import React, {Component} from 'react';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import icon_eco from './images/eco_icon.jpg';
import icon_seeds from './images/seeds.jpg';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PageContent extends Component {

    render() {
        return (
            <div className="description-width">
            <div className="row align-items-center h-100">
                <p className="description font-16 text-center font-weight-bold">
                    <FormattedHTMLMessage id="app.text.first" values={{ what: 'react-intl' }}/>
                    <img className="icon-seeds" src={icon_seeds} width="60" height="30"/>
                </p>
                <div className="description-border"></div>
                <p className="description font-16 text-center font-weight-bold">
                    <FormattedHTMLMessage id="app.text.second" values={{ what: 'react-intl' }}/>
                </p>
                <div className="description-border mb-2"></div>
                <p className="description font-16 font-weight-bold m-auto pt-2">
                    <FormattedHTMLMessage id="app.text.footer" values={{ what: 'react-intl' }}/>
                    <img className="icon-eco" src={icon_eco} width="40" height="40"/>
                </p>
            </div>
                <div className="align-items-center text-center">
                    <a className="text-decoration-none" href="mailto:info@costuco.lt">
                        <span className="d-inline font-email font-16">
                            <FontAwesomeIcon icon={faEnvelope} /> info@costuco.lt
                        </span>
                    </a>
                </div>
            </div>
        );
    }
}

export default PageContent;