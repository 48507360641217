import React, {Component} from 'react';
import logo from './images/Costuco_Logo-pdf.png';
import lt_flag from './images/lt-flag.png';
import en_flag from './images/gb.png';
import PageContent from "./PageContent";
import {IntlProvider} from "react-intl";
import messages_lt from "./translations/lt.json";
import messages_en from "./translations/en.json";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locale: 'en'
        };

        this.messages = {
            lt: messages_lt,
            en: messages_en
        };

        this.setEnLocale = this.setEnLocale.bind(this);
        this.setLtLocale = this.setLtLocale.bind(this);
    }

    setEnLocale() {
        this.setState({
            locale: 'en'
        });
    }

    setLtLocale() {
        this.setState({
            locale: 'lt'
        });
    }

    render() {
        return (
            <div className="App">
                <div className="translator">
                    <a className="ml-2 flag-icon" onClick={this.setEnLocale}><img src={en_flag} width="24" height="16"/></a>
                    <a className="ml-2 flag-icon" onClick={this.setLtLocale}><img src={lt_flag} width="24" height="24"/></a>
                </div>
                <div className="container">
                    <div className="row align-items-center h-100">
                        <img className="logo" src={logo} alt="Costuco.lt"/>
                    </div>
                    <IntlProvider
                        locale={this.state.locale}
                        messages={this.messages[this.state.locale]}>
                        <PageContent/>
                    </IntlProvider>
                </div>
            </div>
        );
    }
}

export default App;